// Bibliotecas
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

// Atom
import { useRecoilState, useRecoilValue } from "recoil";
import { searchaAtom } from "../atoms/searchaAtom";
import { cartAtom } from "../atoms/cartAtom";

const CatalogMenu = ({ brandFound }) => {
  const [search, setSearcha] = useRecoilState(searchaAtom);
  // carrinho state
  const cartState = useRecoilValue(cartAtom);

  return (
    <>
      <div className="flex flex-wrap place-items-center fixed z-10 ">
        <section className="relative mx-auto">
          <nav className={brandFound.menu.nav}>
            <div className="px-5 xl:px-5 py-3 flex w-full items-center">
              <Link className="" to="/">
                <img src={brandFound.menu.logan} className="w-48 md:w-36" />
              </Link>
              {/* NavLink */}
              <ul className="w-full md:flex font-semibold font-heading items-center justify-center px-2">
                <span className="md:w-2/4 md:h-10 h-8 cursor-pointer border bg-gray-300 border-gray-300 text-xs md:text-sm rounded-full flex">
                  <input
                    type="search"
                    name="search"
                    valor={search}
                    onChange={(e) => setSearcha(e.target.value)}
                    placeholder="Search"
                    className="px-4 rounded-l-full rounded-r-full text-xs md:text-sm text-black font-bold focus:outline-none md:w-full w-3/4"
                  />

                  <div className="m-2 md:mr-5 mr-1 text-lg md:flex flex items-center pr-1">
                    <ion-icon name="search-outline"></ion-icon>
                  </div>
                </span>
              </ul>

              {/* Icons */}
              <div className="flex items-center space-x-5">
                <Link
                  to="/car"
                  className="flex items-center bg-white rounded-full md:px-2 px-2 md:py-1.8 py-2 transform hover:scale-110 transition-transform"
                >
                  <div className="relative scale-75">
                    <img
                      className="w-16 md:w-10 transform hover:scale-110 transition-transform"
                      src={brandFound.menu.cartIcon}
                    />
                    <span className="absolute -top-6 left-5 rounded-full bg-red-500 p-0.5 px-2 md:text-xm text-sm text-red-50">
                      {cartState.length}
                    </span>
                  </div>
                </Link>

                <ReactWhatsapp
                  number="+55 85 92890716"
                  message="olá gostaria de conhecer seus produtos"
                  className="bg-white md:px-3 px-2 md:py-3 py-2 rounded-full transform hover:scale-110 transition-transform"
                >
                  <img
                    className="w-16 md:w-8 transform hover:scale-110 transition-transform"
                    src={brandFound.menu.iconWhatApp}
                  />
                </ReactWhatsapp>
              </div>
            </div>
          </nav>
        </section>
      </div>
    </>
  );
};

export default CatalogMenu;
